import { PublicClientApplication, Configuration, AuthenticationResult, AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { User } from '../types/globals';
import { env } from './environment';
import userApi from './apis/iam/userApi';

const msalConfig: Configuration = {
  auth: {
    clientId: env.B2C_CLIENT_ID,
    authority: env.B2C_AUTHORITY,
    redirectUri: env.B2C_REDIRECT_URI,
    knownAuthorities: [env.B2C_KNOWN_AUTHORITY],  
  },
  cache: {
    cacheLocation: 'localStorage', // Can be 'localStorage' or 'sessionStorage' (sessionStorage is more secure against XSS, but the user has to relogin for every new tab)
    storeAuthStateInCookie: false, // Set to true if needed (for older browsers)
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

async function getCurrentAccount(): Promise<AccountInfo>{
  const account = msalInstance.getAllAccounts()[0];
  if (!account) {
    await msalInstance.loginRedirect({ // or loginPopup
      scopes: [
        "openid",
        "profile",
        env.B2C_SCOPE
      ],
    });
    return null;
  }
  return account;
}

async function acquireToken(): Promise<string | null> {
  const account = await getCurrentAccount();

  const request = {
    scopes: [
      "openid",
      "profile",
      env.B2C_SCOPE
    ],
    account: account,
  } as RedirectRequest;

  try {
    const response: AuthenticationResult =
      await msalInstance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (error) {
    console.log(
      "Silent token acquisition failed, acquiring token via redirect",
      error
    );
    await msalInstance.acquireTokenRedirect(request); // or acquireTokenPopup
  }
  return null;
}

async function logout(): Promise<void> {
  await msalInstance.logoutRedirect();
}

async function getCurrentUser():  Promise<User | null> {
  
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
      const account = accounts[0]; // Get the first account
      const user = await userApi.getUserByObjectId(account.idTokenClaims.oid);
      if(user){
        return {
          id: user.id as string,
          b2cObjectId: account.idTokenClaims.oid as string,
          firstName: account.idTokenClaims.userFirstName as string,
          lastName: account.idTokenClaims.userLastName as string,
          email: account.idTokenClaims.email as string,
          jobTitle: account.idTokenClaims.userJobTitle as string,
          zipCode: account.idTokenClaims.userAddressZipCode as string,
          street: account.idTokenClaims.userAddressStreet as string,
          country: account.idTokenClaims.userAddressCountry as string,
          city: account.idTokenClaims.userAddressCity as string,
        };
      }
  }
  return null; // Return null if no accounts are found
}

window.logout = logout;
window.acquireToken = acquireToken;
window.getCurrentUser = getCurrentUser;

export { msalInstance, acquireToken, getCurrentAccount, getCurrentUser };