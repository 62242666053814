import { Pilet } from "piral-core";
import { acquireToken, getCurrentUser } from "./msal";
import { getCurrentTenant, setCurrentTenant } from "./tenant";
import resourceApi from "./apis/iam/resourceApi";
import { Resource } from "@casablanca/casablanca.contracts.iam-client-ts-v1";
import { env } from "./environment";

function getFeedUrl(): string {
  const feedUrl = env.PIRAL_FEED_URL;
  // feedUrl = 'https://feed.piral.cloud/api/v1/pilet/tractor-v2-demo'; //demo
  return feedUrl;
}

async function requestPilets(): Promise<Pilet[]> {
  const token = await acquireToken();

  let currentTenant = await getCurrentTenant();
  const user = await getCurrentUser();
  let tenants: Resource[] = [];
  try{
    const response = await resourceApi.getUsersTenants();
    tenants = response.values;
  } catch{}

  let tenantIdFromRoute: string | null = null;
  if(
    !window.location.pathname.startsWith("/iam/")
    && !window.location.pathname.startsWith("/iam-user/")
    && !window.location.pathname.startsWith("/app/")
    && window.location.pathname !== "/"
  )
  {
    tenantIdFromRoute = window.location.pathname.split('/')[1].toLowerCase();
  }

  if(!currentTenant && tenantIdFromRoute)
  {
    try{
      const tenant = await resourceApi.getTenantByCasablancaId(tenantIdFromRoute);
      const organizationId = await resourceApi.getOrganizationIdByCasablancaId(user.b2cObjectId, tenantIdFromRoute);
      currentTenant = {
        id: tenant.casablancaId,
        name: tenant.displayName,
        organizationId: organizationId
      };
      setCurrentTenant(currentTenant);
    }
    catch{}
  }
  if(!currentTenant)
  {
    if(tenants && tenants.length === 1) 
    {
      try{
        const tenant = tenants[0];
        const organizationId = await resourceApi.getOrganizationIdByCasablancaId(user.b2cObjectId, tenant.casablancaId);
        currentTenant = {
          id: tenant.casablancaId,
          name: tenant.displayName,
          organizationId: organizationId
        };
        setCurrentTenant(currentTenant);
      }
      catch{}
    }
  }

  let tenant = await getCurrentTenant();
  if(window.location.pathname != "/iam/resourceselection" && !tenant)
  {
    window.location.href = `/iam/resourceselection`;
  }
  
  //TODDO: check if user has access to currentTenant (else redirect to /iam/resourceselection)?
  
  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  if(tenant){
    headers["x-tenantid"] = tenant.id;
    headers["x-organizationid"] = tenant.organizationId;
  }
  const response = await fetch(getFeedUrl(), {
    headers: headers,
  });

  if (!response.ok) {
    throw new Error("Failed to fetch pilets");
  }

  const json = await response.json();
  const pilets =  json.items as Pilet[];
  return pilets
}

async function redirectToFirstApp(loadedPilets: string[]) {
  const dashboardPilet = loadedPilets.find(x => x === "@casablanca/dashboard")
  const firstPilet = loadedPilets
    .filter(x => x !== "@casablanca/cloud-ui")
    .filter(x => x !== "@casablanca/iam")
    .filter(x => x !== "@casablanca/iam-user")
    [0];
  const tenant = await getCurrentTenant();
  if(!tenant)
  {
    window.location.href = `/iam/resourceselection`;
  }
  else if(dashboardPilet){
    window.location.href = `/${tenant.id}/${dashboardPilet.replace("@casablanca/", "")}`;
  }
  else if(firstPilet){
    loadedPilets
    window.location.href = `/${tenant.id}/${firstPilet.replace("@casablanca/", "")}`;
  }
} 

export {requestPilets, redirectToFirstApp}

